import React from "react";

export const Footer = () => {
  const local = require("../../assets/maps-and-location.png");
  const time = require("../../assets/time-and-date.png");
  const phone = require("../../assets/ring-phone.png");
  const mail = require("../../assets/email.png");
  const facebook = require("../../assets/facebook.png");
  const ig = require("../../assets/instagram.png");
  const logo = require("../../assets/logofooter.png");

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer className="footer">
      <div className="footerItemContainerGlobal">
        <div>
          <div className="footerItemContainer">
            <img src={local} alt="Imagen completa" className="footerImg" />
            <h4>Dirección</h4>
          </div>
          <span>Los Realejos - 38410, Santa Cruz de Tenerife</span>
        </div>
        <div>
          <div className="footerItemContainer">
            <img src={time} alt="Imagen completa" className="footerImg" />
            <h4>Horario</h4>
          </div>
          <span>Lunes a viernes: 7:00 a 20:00 h</span>
        </div>
        <div>
          <div className="footerItemContainer">
            <img src={mail} alt="Imagen completa" className="footerImg" />
            <h4>Email</h4>
          </div>
          <span>impermeabilizacionesfelipe@gmail.com</span>
        </div>
        <div>
          <div className="footerItemContainer">
            <img src={phone} alt="Imagen completa" className="footerImg" />
            <h4>Teléfono</h4>
          </div>
          <span>+34 630 563 416 / +34 677 244 041</span>
        </div>
      </div>
      <div className="bigLogoContainer">
        <img
          src={logo}
          alt="Imagen completa"
          className="bigLogo"
          style={{"maxWidth": "70%"}}
          onClick={() => scrollToSection("inicio")}
        />
      </div>
      <div>
        <div className="socialLinks">
          <div>
            <img src={facebook} alt="Imagen completa" className="footerImg" />
            <a
              href="https://www.facebook.com/profile.php?id=100095345183762"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visita nuestra página de Facebook
            </a>
          </div>
          <div>
            <img src={ig} alt="Imagen completa" className="footerImg" />
            <a
              href="https://www.instagram.com/impermeabilizacionesfp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Visita nuestro perfil de Instagram
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
